<template>
  <modal class="setMealModel" :isShow="isShow" v-if="posFoodSetMealInfoList.SetMealInfo" @keyup.esc="hide">
    <div class="header">
        <div class="flex-1">
            已选择套餐名称：{{posFoodSetMealInfoList.SetMealInfo.Eat_TcName}} ￥{{posFoodSetMealInfoList.SetMealInfo.Eat_TcPrice}}/份
        </div>
        <el-switch v-show="isReplaceShow" v-model="isReplace" inline-prompt active-text="套餐子菜替换" inactive-text="" active-color="#13ce66" inactive-color="#cecece"></el-switch>
        <div class="tally-box">
            <div class="bnt-jian" @click="setMealNumreduce()"><b class="iconfont icon-jian"></b></div>
            <div class="num">{{setMealNum}}</div>
            <div class="bnt-jia"  @click="setMealNumAdd()" ><b class="iconfont icon-jia"></b></div>
        </div>
    </div>
    <div class="modal-body clearfix">
        <div class="left-nav" ref="nav" >
            <div class="nav-li selected" data-index="0"><span>全部</span></div>
            <div class="nav-li" v-for="(item,index) in posFoodSetMealInfoList.SetMealInfo.SetMealKinds" :key="item" :data-index="index"><span>{{item.Kind_Name}}</span></div>
        </div>
        <div class="right-box">
            <div class="meal-box" v-mouse-scroll v-nav-roll="{nav:'nav'}">
                <div class="pane-box" v-for="item in posFoodSetMealInfoList.SetMealInfo.SetMealKinds" :key="item">
                    <div class="title">
                        {{item.Kind_Name}}<span>（必选项，最少选{{item.Select_Number}}份，最多选{{item.Count_Number}}份）</span>
                    </div>
                    <div class="item-box">
                        <div class="item-li selected" v-for="set in item.SetMealDetails" :key="set">
                            
                            <div class="tit" :class="{selected:set.Is_Selected}" >
                                <tag-sold-out v-if="(set.replaceItem && set.replaceItem.Is_SoldOut) || (!set.replaceItem && set.Is_SoldOut)"></tag-sold-out>
                                <div class="name" @click="foodClick(set,item)" :style="{'padding-right':isReplace && (item.ReplaceType==1 || item.ReplaceType==2) ?'55px':0}">
                                    <span class="inlineNowrap2">
                                        {{set.replaceItem?set.replaceItem.Food_Name:set.Food_Name}}
                                        <span v-if="set.Food_TcPrice>0">（加价：{{set.Food_TcPrice}}元）</span>
                                    </span>
                                    <i class="iconfont icon-gou-fine"></i>

                                    <div class="replace-bnt" v-if="isReplace && (item.ReplaceType==1 || item.ReplaceType==2)" @click.stop="replaceClick(item,set)">替换</div>
                                    <div class="unit">{{set.replaceItem?set.replaceItem.Food_Size:set.Food_Size}}</div>
                                </div>
                                <div class="flavour_bnt" v-if="isDemand && ((set.replaceItem && set.replaceItem.Is_BindEatMas)|| (!set.replaceItem && set.Is_BindEatMas))" @click="flavourClick(set)">
                                    <i class="iconfont icon-bianji"></i>
                                </div>
                            </div>
                            <div class="flex-box" v-if="set.Is_Selected">
                                <div class="tally-box" >
                                    <template v-if="set.Is_Fixed">
                                        <div class="num fixedNum">×{{set.Food_Number}}</div>
                                    </template>
                                    <template v-else>
                                        <div class="bnt-jian" :class="{fixed:set.Is_Fixed}" @click="setMealNumreduce(set)"><b class="iconfont icon-jian"></b></div>
                                        <div class="num">{{set.Food_Number}}</div>
                                        <div class="bnt-jia" :class="{fixed:set.Is_Fixed}" @click="setMealNumAdd(set,item)"><b class="iconfont icon-jia"></b></div>
                                    </template>
                                </div>
                                <div class="describe nowrap2" v-if="isDemand && set._Eat_MasName">要求：{{set._Eat_MasName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
    <!-- 菜品要求操作 -->
    <modal-load :isShow="demandShow" :isUnload="true">
        <demand-model :isShow="demandShow" @closeModel="demandShow=false" :BindPosFoods="BindPosFoods" :masKindsList="masKindsList" @BindPosconfirm="BindPosconfirm"></demand-model>
    </modal-load>
    <!-- 套餐替换菜品 -->
    <modal-load :isShow="selectFootShow" isUnload>
        <select-foot-model :isShow="selectFootShow" :data="replaceData" :notFoodIds="notFoodIds" @close="selectFootShow=false" @change="selectConfirm"/>
    </modal-load>
  </modal>
</template>

<script>
import { deepClone } from '/src/common'
import DemandModel from '../demandModel/demandModel.vue'
import selectFootModel from './selectFootModel/selectFootModel.vue'
/**套餐 */
export default {
    name:"setMealModel",
    components:{
        DemandModel,
        selectFootModel
    },
    props:{
        /**弹层是否显示 */
        isShow:{
            typeof:Boolean,
            default:false
        },
        /**是否判断 强制套餐明细 口味做法 */
        isDemand:{
            typeof:Boolean,
            default:false
        },
        posFoodSetMealInfos:{},
        disRadioOrder:{},
        tcChangeType:{},
        orderNumber:{},
        /**全部菜品数据 */
        posFoodData:{
            typeof:Array,
            default:null
        },
        /**是否不启动替换菜品 */
        isNotReplace:{
            typeof:Boolean,
            default:false
        }
    },
    data(){
        return {
            /**套餐子菜替换 */
            isReplace:false,
            /**套餐数量 */
            setMealNum:1,
            /**套餐数据 */
            posFoodSetMealInfoList:[],
            /**口味要求弹层 */
            demandShow:false,
            BindPosFoods:null,
            //菜品口味
            masKindsList:null,
            /**当前操作的菜品 */ 
            currentItem:null,
            /** 套餐替换菜品 弹层*/
            selectFootShow:false,
            /**要换菜的数据 */
            replaceData:null
        }
    },
    computed:{
        //系统参数配置
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
        /**启用【当套餐子项沽清时，允许下单】， true:开启 false:未开启 */
        isSoldOutDetails(){
            return this.sysSetting?.RunAllowSetmealOrderInWIthSoldOutDetails;
        },
        /**是否有 可替换菜品的分类 */
        isReplaceShow(){
            return !this.isNotReplace && this.posFoodSetMealInfoList?.SetMealInfo?.SetMealKinds?.some(it=>it.ReplaceType==1 || it.ReplaceType==2);
        },
        /**全部菜品  */
        allFoods(){
            let all=[];//菜品
            this.posFoodData?.forEach(category=> {
                category.FoodSubCategorys?.forEach(sub=>{
                    sub.PosFoods?.forEach(item=>{
                        all.push(item);
                    })
                })
            });
            return all;
        },
        /**需要排除的菜品id */
        notFoodIds(){
            let ids=[];
            this.posFoodSetMealInfoList?.SetMealInfo?.SetMealKinds?.forEach(set=>{
                set.SetMealDetails?.forEach(it=>{
                    if(this.currentItem.SetMealDetail_ID!=it.SetMealDetail_ID){//排除当前的菜
                        if(it.Food_ID){
                            ids.push(it.Food_ID);
                            if(it.replaceItem){
                                ids.push(it.replaceItem.Food_ID);
                            }
                        }
                    }
                })
            })
            return ids;
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.init();
            }
        }
    },
    mounted(){
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        /*初始化数据*/
        init(){
            this.isReplace=false;
            if(Number(this.orderNumber)>1){
                this.setMealNum=Number(this.orderNumber);
            }else{
                this.setMealNum=1;
            }
            this.posFoodSetMealInfoList=deepClone((this.posFoodSetMealInfos||[])[0]||{});
            if(this.disRadioOrder!='' && this.tcChangeType==1){
                this.setMealNum=this.disRadioOrder.Eat_Number;
                this.posFoodSetMealInfoList.SetMealInfo.SetMealKinds.forEach(set=>{
                    set.SetMealDetails?.forEach(meal=>{
                        meal.Is_Selected=false;
                        let food=this.disRadioOrder.FoodSetmeals?.find(it=>(it.SetMealDetail_ID && it.SetMealDetail_ID==meal.SetMealDetail_ID) || (!it.SetMealDetail_ID && it.FoodSetmeal_ID==meal.Food_ID));
                        if(food){
                            meal.Is_Selected=true;
                            if(food.FoodSetmeal_Number==this.disRadioOrder.Eat_Number){
                                meal.Food_Number=1;
                            }else{
                                meal.Food_Number= Number(food.FoodSetmeal_Number) / Number(this.disRadioOrder.Eat_Number);
                            }
                            if(food.FoodModifys?.length>0){
                                meal._Eat_MasName=food.FoodModifys.map(it=>it.Mas_Name).join(",")
                                meal._FoodModifys=food.FoodModifys;
                            }
                            if(food.ReplaceFood_ID){//有替换菜时
                                meal.replaceItem=this.allFoods?.find(it=>it.Food_ID==food.ReplaceFood_ID);
                            }
                        }
                    })
                })
            }
        },
        /**数量增加 */
        setMealNumAdd(set,item){
            if(set?.Is_Fixed==true){
                return
            }
            if(!this.isSoldOutDetails && ((set?.replaceItem && set?.replaceItem?.Is_SoldOut) || (!set?.replaceItem && set?.Is_SoldOut))){//已经沽清
                this.$message.warning('已沽清,无法修改数量');
                return
            }
            let itemNumCount=0;
            if(set){
                item.SetMealDetails.map(i=>{
                    if(i.Is_Selected){
                        itemNumCount+=i.Food_Number
                    }
                })
                if(itemNumCount>=item.Count_Number || set.Food_Number>=item.Count_Number){
                    this.$message.warning('选择数量超过套餐限制');
                }else{
                    set.Food_Number++;
                }
            }else{
                this.setMealNum=Number((this.setMealNum+1).toFixed(4));
            }
        },
        /**数量减少 */
        setMealNumreduce(set){
            if(set?.Is_Fixed==true){
                return
            }
            if(!this.isSoldOutDetails && ((set?.replaceItem && set?.replaceItem?.Is_SoldOut) || (!set?.replaceItem && set?.Is_SoldOut))){//已经沽清
                this.$message.warning('已沽清,无法修改数量');
                return
            }
            if(set){
                if(set.Food_Number>1){
                    set.Food_Number--;
                }else{
                    if(set.Is_Fixed==false){
                        set.Is_Selected=false;
                    }
                }
            }else{
                if(this.setMealNum>1){
                    this.setMealNum=Number((this.setMealNum-1).toFixed(4));
                }
            }
        },
        /**选择菜品 */
        foodClick(set,item){
            if(!this.isSoldOutDetails && ((set?.replaceItem && set?.replaceItem?.Is_SoldOut) || (!set?.replaceItem && set?.Is_SoldOut)) && set.Is_Selected==false){
                this.$message.warning(set.Food_Name+'已沽清,无法选择');
                return
            }
            if(set.Is_Fixed==true){
                this.$message.warning(set.Food_Name+'是固定菜品,无法取消');
                return
            }
            //如果菜品数量是2，且最多和最少都是1 的时候
            let temType=0
            if(item.SetMealDetails.length==2 && item.Count_Number==1 && set.Is_Selected==false){
                item.SetMealDetails.forEach(it=>{
                    if(it.Is_Selected==true && it.Food_ID!=set.Food_ID && it.Is_Fixed==false){
                        it.Is_Selected=false
                        return true
                    }else if(it.Is_Selected==true && it.Food_ID!=set.Food_ID && it.Is_Fixed==true){
                        this.$message.warning(it.Food_Name+'是固定菜品,无法取消');
                        temType=1
                        return true
                    }
                })
            }
            if(set.Is_Selected==true){
                set.Is_Selected=false;
            }else{
                if(temType==0){
                    set.Is_Selected=true;
                }
            }
        },
        hide(){
            this.$emit("closeModel")
        },
        confirm(){
            let error=[];
            if(!this.posFoodSetMealInfoList.SetMealInfo?.SetMealKinds){
                this.$message.warning('套餐没有子项菜品,无法添加到菜单');
                return
            }
            let SetMealKinds=this.posFoodSetMealInfoList.SetMealInfo?.SetMealKinds;
            for(let i=0;i<SetMealKinds.length;i++){
                let itemNumCount=0;
                let item=SetMealKinds[i];
                for(let j=0;j<item.SetMealDetails.length;j++){
                    let set=item.SetMealDetails[j];
                    if(set.Is_Selected){
                        if(!this.isSoldOutDetails && ((set?.replaceItem && set?.replaceItem?.Is_SoldOut) || (!set?.replaceItem && set?.Is_SoldOut))){
                            this.$message.warning((set?.replaceItem?.Food_Name||set.Food_Name)+'已沽清,无法添加到点菜列表中');
                            return
                        }
                        itemNumCount+=set.Food_Number;
                        if(this.isDemand && set.Is_BindEatMas && !(set._FoodModifys?.length>0)){//有口味做法要求
                            if(set.replaceItem && set.replaceItem.Is_BindEatMas){
                                error.push("【"+set.replaceItem.Food_Name+"】");
                            }else if(!set.replaceItem && set.Is_BindEatMas){
                                error.push("【"+set.Food_Name+"】");
                            }
                            
                        }
                    }
                }
                if(itemNumCount<item.Select_Number || itemNumCount>item.Count_Number){
                    let title=item.Kind_Name+"分类下【最少选"+item.Select_Number+"份，最多选"+item.Count_Number+"份】，您选择了"+itemNumCount+"份，请修改【"+item.Kind_Name+"】分类下菜品份数！"
                    this.$alert(title, "提示", {confirmButtonText: "确定"});
                    this.$refs.nav?.querySelectorAll(".nav-li")[i+1]?.click();
                    return
                }
                // if(itemNumCount>item.Count_Number){
                //     this.$message.warning( item.Kind_Name+'分类下选择数量超过套餐限制条件');
                //     this.$refs.nav?.querySelectorAll(".nav-li")[i+1]?.click();
                //     return
                // }
            }


            

            if(error.length>0){
                //是否开启如套餐子项菜品未选择绑定做法，则不允许点此套餐， true:开启 false:未开启
                if(this.$cacheData?.globalVariable?.GlobalSysSetting?.RunSetmealDetailRefusedOrderInWithoutBindMas){
                    this.$alert("套餐中"+error.join(",")+'菜品未选择做法!', "提示", {confirmButtonText: "确定"});
                }else{
                    this.$confirm("套餐中"+error.join(",")+'菜品未选择做法，是否继续点单？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$emit("setMealClick",this.setMealNum,this.posFoodSetMealInfoList)
                    }).catch(() => {});
                }
                return
            }
            this.$emit("setMealClick",this.setMealNum,this.posFoodSetMealInfoList)
        },
        /**点击 口味要求 */
        flavourClick(item){
            this.demandShow=true;
            if(item.replaceItem){
                this.BindPosFoods = Object.assign({},item.replaceItem,{
                    _Eat_MasName:item._Eat_MasName,
                    _FoodModifys:item._FoodModifys
                });
            }else{
                this.BindPosFoods= item;
            }
            this.currentItem=item;
        },
        /**口味选择 返回 */
        BindPosconfirm(optFlavors){
            this.demandShow=false;
            if(optFlavors?.length>0){
                this.currentItem._Eat_MasName=optFlavors.map(it=>it.Mas_Name).join(",")
                this.currentItem._FoodModifys=optFlavors;
            }else{
                this.currentItem._Eat_MasName="";
                this.currentItem._FoodModifys=null;
            }
        },
        /**替换菜品 */
        replaceClick(kind,item){
            this.replaceData={
                ReplaceType:kind.ReplaceType,//替换菜品控制类型 0或空:不允许替换 1:任意替换 2:控制替换
                ReplaceMaxPercent:kind.ReplaceMaxPercent,//控制替换的单价上浮百分比
                ReplaceMinPercent:kind.ReplaceMinPercent,//控制替换的单价下浮百分比
                MealDetails:item,//子菜品
            }
            this.currentItem=item;
            this.selectFootShow=true;
        },
        selectConfirm(item){
            if(item.Food_ID==this.currentItem.Food_ID){
                //如何替换的菜与原有菜相同 则还原 不需要替换了
                delete this.currentItem.replaceItem;
            }else{
                //替换的菜
                this.currentItem.replaceItem=Object.assign({},item);
            }
            this.currentItem._Eat_MasName="";//清除之前的口味做法
            this.currentItem._FoodModifys=null;
            this.selectFootShow=false;
        }
    }
}
</script>

<style lang="sass">
@import "./setMealModel.scss";
</style>